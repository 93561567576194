import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { margins, mobileThresholdPixels }
  from './v2/styledComponents';
import iosImg from '../../assets/footer/appstore.svg';
import androidImg from '../../assets/footer/googleplay.svg';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: ${margins.m};
    flex-direction: column;
  }
`;

const Icon = styled.img`
  width: 180px;
  margin: ${props => props.smallTopMargin ? margins.s : margins.l} ${margins.s} 0px ${margins.s};
  cursor: pointer;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 180px;
    margin: 0px ${margins.s} ${margins.s} ${margins.s};
  }
`;

const A = styled.a.attrs({
  target: '_blank',
})`
  width: fit-content;
  text-decoration: none;
  color: inherit;
`;

const MobileApps = ({ smallTopMargin }) => (
  <Container>
    <A
      target="_blank" rel="noreferrer noopener"
      href={'https://itunes.apple.com/us/app/tilli/id1280578121'}
    >
      <Icon smallTopMargin={smallTopMargin} src={iosImg} alt={'Téléchager l\'apppli mobile pour iPhone'} />
    </A>
    <A
      target="_blank" rel="noreferrer noopener"
      href={'https://play.google.com/store/apps/details?id=fr.tilli.app'}
    >
      <Icon smallTopMargin={smallTopMargin} src={androidImg} alt={'Téléchager l\'apppli mobile pour Android'} />
    </A>
  </Container>
);

MobileApps.propTypes = {
  smallTopMargin: PropTypes.bool,
};

MobileApps.defaultProps = {
  smallTopMargin: false,
};

export default MobileApps;
